import React from 'react';
import Img from '../images/yasmine.jpeg';

export default () => (

	<div className='aboutpage-CN'>
	<h4> MY STORY </h4>		
	<div className="aboutpage-split">
		<img src={Img} id="about-img"></img>
		<div className="aboutpage-text">
			<h4 class="name"> Yasmine Isaya </h4>
			<p>
			To know me is to know a bit of my fashion love story. I am a stylist and art director who occasionally has a swift pen in writing poetry. 
			Fashion was my first love and we met when I was 7 years old. I was introduced by my mother through a TLC network show called “ What Not To Wear”, from that day on I was hooked and I haven’t strayed away since.  My passion became my career in 2018 when I worked on my first commercial gig. Since then I’ve done editorial shoots, e-commerce, campaign, film, personal styling and more. The most fulfilling part of my work is being able to connect with other creatives and help each other make our art come to life for people to experience. My first love will always be styling but I have grown to have a passion for art direction and creative writing as well (poetry). I strive to continue to find ways to express my creativity and make harmonious projects with all of my art forms.
			</p>
		</div>
	</div>
	</div>
);
