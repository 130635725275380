import React from 'react';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import Img1 from '../../images/editorial/editorial1.jpeg';
import Img2 from '../../images/editorial/editorial2.jpeg';
import Img3 from '../../images/editorial/editorial3.jpeg';
import Img4 from '../../images/editorial/editorial4.jpeg';
import Img5 from '../../images/editorial/editorial5.jpeg';
import Img6 from '../../images/editorial/editorial6.jpeg';
import Img7 from '../../images/editorial/editorial7.jpeg';
import Img8 from '../../images/editorial/editorial8.jpeg';
import Img9 from '../../images/editorial/editorial9.jpeg';
import Img10 from '../../images/editorial/editorial10.jpeg';
import Img11 from '../../images/editorial/editorial11.jpeg';


export default () => (
	<div className='editorial-template'>
	<h4> Amplify </h4>
	<p> Connecting with our senses allows us to amplify the way we interact with the world. Every movement, moment and scent becomes vivid.
	</p>

	<hr className="editorial-underline"></hr>

	<p> Photographer - Francois Mittins </p>
	<p> Stylist - Yasmine Isaya </p>
	<p> Mua -  Yelena Perunov </p>
	<p> Model - Georgia Parks </p>
	<p>	Location - Studio 2020</p>

		<div class="project-split">
				<img class="editorial-img" src={Img1}></img>
				<img class="editorial-img" src={Img2}></img>
				<img class="editorial-img" src={Img3}></img>
				<img class="editorial-img" src={Img4}></img>
				<img class="editorial-img" src={Img5}></img>
				<img class="editorial-img" src={Img6}></img>
				<img class="editorial-img" src={Img7}></img>
				<img class="editorial-img" src={Img8}></img>
				<img class="editorial-img" src={Img9}></img>
				<img class="editorial-img" src={Img10}></img>
				<img class="editorial-img" src={Img11}></img>

	</div>
	</div>
);
