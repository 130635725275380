import React from 'react';
import commercial from '../images/campaign/Campaign1.jpeg';
import { FaCaretRight, FaChevronRight} from 'react-icons/fa';


export default () => (
	<div className='section-CN'>

	<div class="service-split">
		<img class="service-split-img" src={commercial}></img>
		<div>
		<h4>
		Commercial Styling
		</h4>
		<p>If you’re looking to shoot an innovative new campaign for your brand whether you are a corporation, designer or beauty brand, I’m the person to come to for the wardrobe that matches the vision.</p>
			
		</div>
	</div>

	</div>
);
