import React from 'react';
import { FaCaretRight, FaChevronRight} from 'react-icons/fa';
import art from '../images/bts/bts2.jpeg';

export default () => (
	<div className='section-CN'>

	<div class="service-split">
		<img class="service-split-img" src={art}></img>
		<div>
		<h4>
		Art Direction
		</h4>
		<p>If you’re working on a film and or commercial and need someone to bring the ideas to fruition when it comes to your visual, look no further. Props, wardrobe, location scouting, music, story line, all of these aspects come into play when conceptualising a project.</p>
			
		</div>
	</div>

	</div>
);
