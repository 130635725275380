import React from 'react';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import Img1 from '../../images/editorial3/editorial1.jpg';
import Img2 from '../../images/editorial3/editorial2.jpg';
import Img3 from '../../images/editorial3/editorial3.jpg';
import Img4 from '../../images/editorial3/editorial4.jpg';
import Img5 from '../../images/editorial3/editorial5.jpg';
import Img6 from '../../images/editorial3/editorial6.jpg';
import Img7 from '../../images/editorial3/editorial7.jpg';
import Img8 from '../../images/editorial3/editorial8.jpg';
import Img9 from '../../images/editorial3/editorial9.jpg';
import Img10 from '../../images/editorial3/editorial10.jpg';
import Img11 from '../../images/editorial3/editorial11.jpg';
import Img12 from '../../images/editorial3/editorial12.jpg';
import Img13 from '../../images/editorial3/editorial13.jpg';
import Img14 from '../../images/editorial3/editorial14.jpg';
import Img15 from '../../images/editorial3/editorial15.jpg';
import Img16 from '../../images/editorial3/editorial16.jpg';
import Img17 from '../../images/editorial3/editorial17.jpg';
import Img18 from '../../images/editorial3/editorial18.jpg';
import Img19 from '../../images/editorial3/editorial19.jpg';
import Img20 from '../../images/editorial3/editorial20.jpg';
import Img21 from '../../images/editorial3/editorial21.jpg';
import Img22 from '../../images/editorial3/editorial22.jpg';
import Img23 from '../../images/editorial3/editorial23.jpg';
import Img24 from '../../images/editorial3/editorial24.jpg';
import Img25 from '../../images/editorial3/editorial25.jpg';
import Img26 from '../../images/editorial3/editorial26.jpg';
export default () => (
	<div className='editorial-template'>
	<h4> Bloom </h4>
	<p> Rebirth. Feeling the fresh wind of spring caressing you. Seeing the flowers slowly coming back to life just as you are waking up from a long dream. Bloom is the Rebirth of the Soul.  </p>

	<hr className="editorial-underline"></hr>

	<p> Fashion Stylist - Susann Kilduff </p>
	<p> Assistant stylist - Ashleigh Williamson </p>
	<p> Photographer - Kaison Annema </p>
	<p> Model - Zuleima Sargsyan </p>

		<div class="project-split">
				<img class="project-img" src={Img1}></img>
				<img class="project-img" src={Img2}></img>
				<img class="project-img" src={Img3}></img>
				<img class="project-img" src={Img4}></img>
				<img class="project-img" src={Img5}></img>
				<img class="project-img" src={Img6}></img>
				<img class="project-img" src={Img7}></img>
				<img class="project-img" src={Img8}></img>
				<img class="project-img" src={Img9}></img>
				<img class="project-img" src={Img10}></img>
				<img class="project-img" src={Img11}></img>
				<img class="project-img" src={Img12}></img>
				<img class="project-img" src={Img13}></img>
				<img class="project-img" src={Img14}></img>
				<img class="project-img" src={Img15}></img>
				<img class="project-img" src={Img16}></img>
				<img class="project-img" src={Img17}></img>
				<img class="project-img" src={Img18}></img>
				<img class="project-img" src={Img19}></img>
				<img class="project-img" src={Img20}></img>
				<img class="project-img" src={Img21}></img>
				<img class="project-img" src={Img22}></img>
				<img class="project-img" src={Img23}></img>
				<img class="project-img" src={Img24}></img>
				<img class="project-img" src={Img25}></img>
				<img class="project-img" src={Img26}></img>
				
		</div>
	</div>
);
