import React from 'react';
import Img from '../images/yasmine.jpeg';
import SecondPage from './aboutpage';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import './scroll'


export default function About() {
  return (
	<div className='about-section'>
    <div class='about-split animated reveal fade-bottom'>
      <div class='about-split-left'>
        <h4>
        About Yasmine Isaya
        </h4>
    		<p>
        Fashion was my first love and we met when I was 7 years old.  My passion became my career in 2018 when I worked on my first commercial gig. Since then I’ve done editorial shoots, e-commerce, campaign, film, personal styling and more. The most fulfilling part of my work is being able to connect with other creatives and help each other make our art come to life for people to experience. My first love will always be styling but I have grown to have a passion for art direction and creative writing as well (poetry). I strive to continue to find ways to express my creativity and make harmonious projects with all of my art forms.

        </p>
        <Link to='/aboutpage'>
          <button className="button2" >Learn more <BsArrowRight class= "arrow-image" width={'2rem'}></BsArrowRight> </button>
        </Link>
      </div>
      <img class= "about-image" src={Img}></img>
    </div>
  </div>


);
}
