import React from 'react';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import Img1 from '../../images/campaign/Campaign1.jpeg';
import Img2 from '../../images/campaign/Campaign2.jpeg';
import Img3 from '../../images/campaign/Campaign3.jpeg';
import Img4 from '../../images/campaign/Campaign4.jpeg';
import Img5 from '../../images/campaign/Campaign5.jpeg';


export default () => (
	<div className='campaign-template'>
	<h4> NRML E-com Spring 2022 </h4>
	<p> E-commerce and social media campaign for spring 2022  </p>

	<hr className="campaign-underline"></hr>

	<p> Photographer - Fitch Jean </p>
	<p> Stylist - Yasmine Isaya </p>
	<p> Model - Tatiana Nkuba</p>
	<p> Model - Zeggy Mercy</p>
	<p> Model - City Fidelia</p>

		<div class="campaign-split">
				<img class="film-img" src={Img1}></img>
				<img class="film-img" src={Img2}></img>
				<img class="film-img" src={Img3}></img>
				<img class="film-img" src={Img4}></img>
				<img class="film-img" src={Img5}></img>
	</div>
	</div>
);
