import React from 'react';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import Img1 from '../../images/trees.jpg';
import Img2 from '../../images/trees.jpg';
import Img3 from '../../images/contact3.jpg';
import Img4 from '../../images/film.jpg';
import Img5 from '../../images/trees.jpg';
import Img6 from '../../images/process2.jpg';


export default () => (
	<div className='editorial-template'>
	<h4> Girlfriends Unfiltered  </h4>
	<p>  A little glimpse into us. This is an interview style journal entry of 3 girlfriends who compliment each other. </p>

	<hr className="editorial-underline"></hr>

	<p> Styling & Creative Direction - Yasmine Isaya  </p>
	<p> Videography & Editing - Maxime Sanchez </p>
	<p> Model - Yasmine Isaya</p>
	<p> Model - Lucie Atangana</p>
	<p> Model - Tatiana Nkuba</p>

		<div class="project-split">
				<img class="project-img" src={Img1}></img>
				<img class="project-img" src={Img2}></img>
				<img class="project-img" src={Img3}></img>
				<img class="project-img" src={Img4}></img>
				<img class="project-img" src={Img5}></img>
				<img class="project-img" src={Img6}></img>
	</div>
	</div>
);
