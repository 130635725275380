import React from 'react';
import editorial from '../images/editorial/editorial1.jpeg';
import art from '../images/bts/bts2.jpeg';
import commercial from '../images/campaign/Campaign1.jpeg';
import styling from '../images/bts/bts1.jpeg';

export default () => (
	<div class="service">
	<div class="service-text">
		<h4 class='animated reveal fade-bottom'>Services</h4>
		<p class='animated reveal fade-bottom'>Allow me to help your creative vision for your photo and video shoots come to life. If you’ve ever had an original concept whether it's a music video, fashion film, birthday shoot, themed photoshoot etc, show me your mood boards and we’ll make it happen, nothing is off the table. (in the studio or outdoors).</p>
	</div>
		<div class="container">
		<div class="inner-container">
		<h4>Editorial</h4>
    <div class="item pre animated reveal fade-bottom"> <span class="icon feature_box_col_one"></span>
		<img src={editorial} alt=""></img>
		<p>• Music videos</p>
		<p>• Fashion Films </p>
		<p>• Creative Photoshoots</p>
    </div>
		</div>
		<div class="inner-container">
		<h4>Art Direction</h4>
    <div class="item present animated reveal fade-bottom"> <span class="icon feature_box_col_two"></span>
		<img src={art} alt=""></img>
		<p>• Films</p>
		<p>• Commercials</p>
		<p>• Creative Video</p>
    </div>
		</div>
		<div class="inner-container">
		<h4>Commercial Styling</h4>
    <div class="item post animated reveal fade-bottom"> <span class="icon feature_box_col_three"></span>
		<img src={commercial} alt=""></img>
		<p padding-top={'3rem'}>• Campaign</p>
		<p>• Model Test Shoot</p>
		<p>• E-comm for Brand</p>
    </div>
		</div>
		<div class="inner-container">
		<h4>Personal Styling</h4>
    <div class="item pre animated reveal fade-bottom"> <span class="icon feature_box_col_one"></span>
		<img src={styling} alt=""></img>
		<p>• Finding your signature style</p>
		<p>• Building your capsule wardrobe from scratch</p>
		<p>• Finding outfits that tailor to your body type and personal taste</p>
    </div>
		</div>
		</div>
		
	</div>
);
