import React from 'react';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import Img1 from '../../images/editorial4/editorial1.jpg';
import Img2 from '../../images/editorial4/editorial2.jpg';
import Img3 from '../../images/editorial4/editorial3.jpg';
import Img4 from '../../images/editorial4/editorial4.jpg';
import Img5 from '../../images/editorial4/editorial5.jpg';
import Img6 from '../../images/editorial4/editorial6.jpg';
import Img7 from '../../images/editorial4/editorial7.jpg';
import Img8 from '../../images/editorial4/editorial8.jpg';
import Img9 from '../../images/editorial4/editorial9.jpg';
import Img10 from '../../images/editorial4/editorial10.jpg';


export default () => (
	<div className='editorial-template'>
	<h4> NEXT Models </h4>
	<p> Portraits of Karina. Model test shoot for Karina’s portfolio. </p>

	<hr className="editorial-underline"></hr>

	<p> Fashion Stylist - Yasmine Isaya </p>
	<p> Assistant stylist - Bernie Gracieuse </p>
	<p> Photographer - Rebecca Alonzo </p>
	<p> Assistant photographer - Audrey-Ann Beaule</p>
	<p> Hairstylist - Dominika Sokalska</p>
	<p> MUA - Maxine Gauthier</p>
	<p> Model - Karina Savoie </p>
	<p>	Location - Bravaa Studio</p>

		<div class="project-split">
				<img class="project-img" src={Img1}></img>
				<img class="project-img" src={Img2}></img>
				<img class="project-img" src={Img3}></img>
				<img class="project-img" src={Img4}></img>
				<img class="project-img" src={Img5}></img>
				<img class="project-img" src={Img6}></img>
				<img class="project-img" src={Img7}></img>
				<img class="project-img" src={Img8}></img>
				<img class="project-img" src={Img9}></img>
				<img class="project-img" src={Img10}></img>
		</div>
	</div>
);
