import React from 'react';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import Img1 from '../images/campaign/Campaign1.jpeg';
import Img2 from '../images/trees.jpg';
import Img3 from '../images/contact3.jpg';
import Img4 from '../images/film.jpg';
import Img5 from '../images/trees.jpg';
import Img6 from '../images/process2.jpg';


export default () => (
	<div className='campaign'>
	<h4>Campaign and Commercial Prints</h4>
		<div class="campaign-split">

			<a class="editorial-item" href="/campaign/campaign1">
			<div class="editorial-container">
					<img class="portfolio-gallery" src={Img1}></img>
				<div class="project-overlay">
					<div class="editorial-text">NRML E-com Spring 2022</div>
				</div>
			</div>
			</a>
		</div>
	</div>
);
