import React from 'react';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import Img1 from '../images/film/film4.jpeg';
import Img2 from '../images/film2/film1.png';

export default () => (
	<div className='film'>
	<h4>Film</h4>

		<div class="film-split">
		<a class="editorial-item" href="/film/film1">
			<div class="editorial-container">
					<img class="portfolio-gallery" src={Img1}></img>
				<div class="project-overlay">
					<div class="editorial-text">An Ode To the 90s</div>
				</div>
			</div>
			</a>

			<a class="editorial-item" href="/film/film2">
			<div class="editorial-container">
					<img class="portfolio-gallery mth" src={Img2}></img>
				<div class="project-overlay">
					<div class="editorial-text">Plus que des Cheveux (More than Hair)</div>
				</div>
			</div>
			</a>

	


	</div>
	</div>
);
