import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import Img_logo from '../images/logo.png';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Button } from './Button';
import { useLocation } from "react-router";

var prevScrollpos = window.pageYOffset;
window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;

    if (prevScrollpos > currentScrollPos) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-100px";
    }
    prevScrollpos = currentScrollPos;
    if (currentScrollPos === (0, 0)){
      document.getElementById("navbar").style.top = "0";

    
    }
  }
     

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>
};


function Navigation() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if(window.innerWidth <= 960){
      setButton(false);
    } else {
      setButton(true);
    }
  }

//Service drop down
function DropDown() {
  document.getElementById("myDropdown").classList.toggle("show");
}

// Close the dropdown if the user clicks outside of it
window.onclick = function(event) {
  if (!event.target.matches('.drop-port')) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
  }
}


  window.addEventListener('resize', showButton);
  return (

  <nav className='navbar' id="navbar">
      <div class="navbar-left">
         <a href="/" aria-current="page" class="w-inline-block w--current">
         <img class="logo" src={Img_logo} href='/' alt="Image"></img>
         </a>
      </div>
      <div className='menu-icon' onClick={handleClick}>
        <i className={click ? 'fa fa-times':'fa fa-bars'}></i>
      </div>
      <div class="navbar-right">

        <ScrollToTop>
        <ul className={click ? 'nav-menu active':'nav-menu'}>
        <li>
          <NavLink to="/" className='nav-links' onClick={closeMobileMenu}>Home</NavLink>
        </li>
        <li>
          <NavLink to="/aboutpage" className='nav-links' onClick={closeMobileMenu}>About</NavLink>
        </li>
        <li>
          <NavLink to="/service" className='nav-links' onClick={closeMobileMenu}>Services</NavLink>
        </li>
    
        <li>
          <div class="dropdown">
            <NavLink to="/portfoliopage" className='dropbtn' id="drop-port">Portfolio ‎ ‎ <i class="fa fa-caret-down">  </i></NavLink>
            <div class="dropdown-content" id="myDropdown" aria-labelledby="navbarDropdown" onhov>
                <NavLink to="/editorial" className='nav-links a' onClick={closeMobileMenu}>Editorial</NavLink>
                <NavLink to="/Campaign" className='nav-links a' onClick={closeMobileMenu}>Campaign and Commercial Prints</NavLink>
                <NavLink to="/Film" className='nav-links a' onClick={closeMobileMenu}>Film</NavLink>  
              </div>
          </div> 
        </li>

        <li>
          <NavLink to="/contactpage" className='nav-links' onClick={closeMobileMenu}>Contact</NavLink>
        </li>

        </ul>
      </ScrollToTop>
      </div>
</nav>

  );
}

export default Navigation;
