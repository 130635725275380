import React from 'react';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import Img1 from '../images/editorial3/editorial1.jpg';
import Img2 from '../images/campaign/Campaign1.jpeg';
import Img3 from '../images/film/film6.jpeg';

export default () => (
<div className='portfolio'>
	    <h4>Explore my Portfolio</h4>

    
    <div class="portfolio-div">
        <div class="portfolio-container">
            <a class="portfolio-item" href="/editorial">
                <img  class="portfolio-img" src={Img1} alt=""></img></a>
            <h4>Editorial</h4>
            <p>Allow me to help your creative vision for your photo and video shoots come to life. Any original concept whether it's a music video, fashion film, birthday shoot, themed photoshoot etc, we’ll make it happen.</p>
            <Link to='/editorial'>
			<button className="portfolio-button">View More</button>
			</Link>
        </div>
        <div class="portfolio-container">
            <a class="portfolio-item" href="/campaign"><img  class="portfolio-img" src={Img2} alt=""></img></a> 
            <h4>Campaign & Commercial Prints</h4>
            <p>If you’re looking to shoot an innovative new campaign for your brand whether you are a corporation, designer or beauty brand, I’m the person to come to for the wardrobe that matches the vision.</p>
            <Link to='/campaign'>
			<button className="portfolio-button">View More</button>
			</Link>
        </div>
        <div class="portfolio-container">
            <a class="portfolio-item" href="/Film"><img  class="portfolio-img" src={Img3} alt=""></img></a> 
            <h4>Film</h4>
            <p>write paragraph here</p>
            <Link to='/Film'>
			<button className="portfolio-button">View More</button>
			</Link>
        </div>
	</div>
</div>
);
