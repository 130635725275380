import React from 'react';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import Video from '../../images/plus_que_des_cheveux.mp4';



export default () => (
	<div className='editorial-template'>
	<h4> More Than Hair </h4>
	<p> Sitting for the first time in a barbershop, a young black boy will discover for the first time the symbolic power of black hair. </p>

	<hr className="editorial-underline"></hr>

	<p> Director - Fitch Jean </p>
	<p> Producer - Amir Zargara </p>
	<p> Production Design & Costume Design - Yasmine Isaya</p>


		<div class="project-split video-div">
	


			<iframe src={Video} onloadedmetadata="this.autoplay = true" allow="autoplay" id="myVideo" type="video/mp4">
         
        </iframe>



			
	</div>
	</div>
);
