import React from 'react';
import { FaCaretRight, FaChevronRight} from 'react-icons/fa';
import editorial from '../images/editorial/editorial1.jpeg';

export default () => (
	<div className='section-CN'>

	<div class="service-split">
		<img class="service-split-img" src={editorial}></img>
		<div>
		<h4>
		Editorial
		</h4>
		<p>Allow me to help your creative vision for your photo and video shoots come to life. If you’ve ever had an original concept whether it's a music video, fashion film, birthday shoot, themed photoshoot etc, show me your mood boards and we’ll make it happen, nothing is off the table. (in the studio or outdoors).</p>
			
		</div>
	</div>
	</div>
 );
