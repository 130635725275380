import React from 'react';
import { FaCaretRight, FaChevronRight} from 'react-icons/fa';
import styling from '../images/bts/bts1.jpeg';

export default () => (
	<div className='section-CN'>

	<div class="service-split">
		<img class="service-split-img" src={styling}></img>
		<div>
		<h4>
		Personal Styling
		</h4>
		<p>If you’re looking to develop your personal style and or develop your brand image, whether that be for your own personal elevation, your career or your social media platform, I’m here to help you curate that and offer you my expert advice on how to achieve the image you want for yourself. </p>
		</div>
	</div>

	</div>
);
