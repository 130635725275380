import React from 'react';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import Img1 from '../../images/editorial2/editorial1.jpeg';
import Img2 from '../../images/editorial2/editorial2.jpeg';
import Img3 from '../../images/editorial2/editorial3.jpeg';
import Img4 from '../../images/editorial2/editorial4.jpeg';
import Img5 from '../../images/editorial2/editorial5.jpeg';
import Img6 from '../../images/editorial2/editorial6.jpeg';
import Img7 from '../../images/editorial2/editorial7.jpeg';
import Img8 from '../../images/editorial2/editorial8.jpeg';
import Img9 from '../../images/editorial2/editorial9.jpeg';

export default () => (
	<div className='editorial-template'>
	<h4> Facets of Sandra </h4>
	<p> 3 friends, a studio space, lots of brainstorming and a last minute concept that turned into showcasing the range of our beautiful model for the evening.  </p>

	<hr className="editorial-underline"></hr>

	<p> Photographer - Fitch Jean </p>
	<p> stylist - Yasmine Isaya </p>
	<p> Model - Sandra Bittar </p>	
	<p>	Location - Lenz Studio </p>

		<div class="project-split">
			<img class="editorial-img" src={Img1}></img>
			<img class="editorial-img" src={Img2}></img>
			<img class="editorial-img" src={Img3}></img>
			<img class="editorial-img" src={Img4}></img>
			<img class="editorial-img" src={Img5}></img>
			<img class="editorial-img" src={Img6}></img>
			<img class="editorial-img" src={Img7}></img>
			<img class="editorial-img" src={Img8}></img>
			<img class="editorial-img" src={Img9}></img>
		</div>
	</div>
);
